import React from 'react'
import LayoutConstant from '@lib/constants/layout.constant'

function TenantCreationPage() {
  return <></>
}

TenantCreationPage.pageMeta = {
  metaTitle: 'Create tenant',
  metaDescription: 'Create tenant',
  metaKeywords: '',
  path: '/tenant-creation',
  layout: LayoutConstant.Empty,
}

export default TenantCreationPage
